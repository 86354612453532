




























import { Dictionary } from "@/types/helpers";
import { Component, Vue } from "vue-property-decorator";
import memberModule from "@/store/modules/members";
import { Member } from "@/types/member";

@Component
export default class SelectMember extends Vue {
  get members(): Dictionary<string>[] {
    const mbrs: Member[] = memberModule.accepted;
    if (!mbrs) return [];
    return mbrs.map((item: Member) => ({
      value: item.id.toString(),
      text: item.name,
      picture: item.picture,
    }));
  }
}
