





























































































import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import taskModule from "@/store/modules/tasks";
import groupModule from "@/store/modules/groups";
import { Task } from "@/types/task";
import TaskListItem from "@/components/task/TaskItemList.vue";
import { Dictionary, TaskType } from "@/types/helpers";
import moment from "moment";
import SearchBar from "@/components/SearchBar.vue";
import Paginate from "@/components/utility/Paginate.vue";

@Component({
  components: {
    TaskListItem,
    SearchBar,
    Paginate,
  },
})
export default class TaskList extends Vue {
  @Ref() readonly search!: SearchBar;
  isFiltered = false;
  filteredTasks: Task[] = [];
  page = 1;
  perPage = 10;
  activeFilters: Dictionary<string> = {};

  get groupId(): string {
    return groupModule.selectedId;
  }

  get pages(): number {
    return Math.ceil(this.tasks.length / this.perPage);
  }

  get visibleTasks(): Task[] {
    return this.tasks.slice(
      (this.page - 1) * this.perPage,
      this.page * this.perPage
    );
  }

  get tasks(): Task[] {
    return this.isFiltered ? this.filteredTasks : taskModule.tasksFuture;
  }

  updateState(state: boolean): void {
    this.isFiltered = state;
  }

  loadTasks(tasks: Task[]): void {
    this.filteredTasks = tasks;
  }

  isLoaded(): boolean {
    return this.search ? this.search.isLoaded || !this.isFiltered : true;
  }

  get tasksGrouped(): Dictionary<Task[]> {
    let tasksByDays = {};
    this.visibleTasks
      .sort((a: Task, b: Task) => moment(a.due_at).diff(moment(b.due_at)))
      .forEach((task: Task) => {
        const due = moment(task.due_at);
        const key = due.endOf("day").fromNow();
        if (tasksByDays[key] == undefined) {
          Vue.set(tasksByDays, key, [task]);
        } else {
          tasksByDays[key].push(task);
        }
      });
    return tasksByDays;
  }

  get projects(): Task[] {
    return this.tasks.filter(
      (item) => item.tasktype_id == TaskType.PROJECT.toString()
    );
  }

  @Watch("tasks")
  updatePage(): void {
    this.page = 1;
  }

  updateFilters(filters: Dictionary<string>): void {
    this.activeFilters = filters;
  }
}
