
















import { Dictionary } from "@/types/helpers";
import { Component, Vue, Prop, VModel } from "vue-property-decorator";

@Component
export default class FilterList extends Vue {
  @VModel({ type: String }) filter!: string;
  @Prop() items!: Dictionary<Dictionary<string>>;
  @Prop() name!: string;
}
