


















































































import { Task } from "@/types/task";
import { Component, Prop, Vue } from "vue-property-decorator";
import memberModule from "@/store/modules/members";
import subjectModule from "@/store/modules/subjects";
import taskModule from "@/store/modules/tasks";
import { Member } from "@/types/member";
import { Subject } from "@/types/subject";
import TinyColor from "tinycolor2";
import { TaskType } from "@/types/helpers";

@Component
export default class TaskItemList extends Vue {
  @Prop() task!: Task;
  @Prop({ default: false }) hasDueDate!: boolean;

  get author(): Member | undefined {
    return memberModule.getMember(this.task.user_id);
  }

  get subject(): Subject | undefined {
    return subjectModule.getSubject(this.task.subject_id);
  }

  get isTextDark(): boolean {
    if (!this.subject) return false;
    const color = new TinyColor(this.subject.color);
    return color.getLuminance() < 0.228;
  }

  get isProject(): boolean {
    return this.task.tasktype_id == TaskType.PROJECT.toString();
  }

  get countReactions(): number {
    return this.task.reactions_list.reduce((a, b) => a + b.count, 0);
  }

  get hasQuestions(): boolean {
    return this.task.questions_count != "0";
  }

  get hasReactions(): boolean {
    return this.task.reactions_list && this.task.reactions_list.length > 0;
  }

  async finished(): Promise<void> {
    this.task.has_finished = !this.task.has_finished;

    const data = {
      task_id: this.task.id,
      hasFinished: this.task.has_finished,
    };

    try {
      await taskModule.setFinish(data);
      this.$toast.success(this.$t("global.success").toString());
    } catch (err) {
      this.$toast.error(this.$t("global.errors.unknown").toString());
    }
  }
}
