var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.task && _vm.subject)?_c('v-list-item',{staticClass:"task-item-list mb-2",class:{ finished: _vm.task.has_finished },attrs:{"to":{
    name: 'taskDisplay',
    params: {
      task_id: _vm.task.id,
    },
  }}},[_c('v-list-item-icon',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t(("task.types." + (_vm.task.tasktype_id) + ".icon")))+" ")])]}}],null,false,382751910)},[_c('span',[_vm._v(_vm._s(_vm.$tc(("task.types." + (_vm.task.tasktype_id) + ".label"), 1)))])])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.task.title)+" ")]),(_vm.hasDueDate)?_c('v-list-item-subtitle',[_c('timeago',{attrs:{"datetime":_vm.task.due_at}})],1):_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t("task.createBy"))+" "),_c('v-avatar',{staticClass:"profile",attrs:{"color":"primary","size":"16"}},[(_vm.author && _vm.author.picture)?_c('v-img',{attrs:{"src":_vm.author.picture}}):_vm._e()],1),_vm._v(" "+_vm._s(_vm.author.firstname)+" "),_c('timeago',{attrs:{"datetime":_vm.task.created_at}}),(_vm.hasQuestions)?_c('span',{staticClass:"ml-2"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-message-question-outline")]),_vm._v(" "+_vm._s(_vm.task.questions_count))],1):_vm._e(),(_vm.hasReactions)?_c('span',{staticClass:"ml-2"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-drama-masks")]),_vm._v(" "+_vm._s(_vm.countReactions))],1):_vm._e()],1)],1),_c('v-list-item-action-text',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":_vm.task.has_finished ? 'success' : 'default'},on:{"click":function($event){$event.preventDefault();return _vm.finished.apply(null, arguments)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.task.has_finished ? "mdi-checkbox-marked" : "mdi-checkbox-blank-outline")+" ")])]}}],null,false,2504462967)},[_c('span',[_vm._v(_vm._s(_vm.$t("task.finish.tooltip")))])]),(_vm.task.isPrivate)?_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-lock")]):_vm._e(),_c('v-chip',{attrs:{"small":"","color":_vm.subject.color,"dark":_vm.isTextDark,"to":{
        name: 'subjectDisplay',
        params: {
          subject_id: _vm.subject.id,
        },
      }}},[_vm._v(" "+_vm._s(_vm.subject.name)+" ")])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }